import Pagination from '../vc-pagination/locale/zh_TW';
import DatePicker from '../date-picker/locale/zh_TW';
import TimePicker from '../time-picker/locale/zh_TW';
import Calendar from '../calendar/locale/zh_TW';
import ColorPicker from '../color-picker/locale/zh_TW';
export default {
    locale: 'zh-tw',
    Pagination,
    DatePicker,
    TimePicker,
    Calendar,
    ColorPicker,
    Table: {
        filterTitle: '篩選器',
        filterConfirm: '確定',
        filterReset: '重置',
        selectAll: '全部選取',
        selectInvert: '反向選取',
        sortTitle: '排序',
        expand: '展開行',
        collapse: '關閉行',
    },
    Modal: {
        okText: '確定',
        cancelText: '取消',
        justOkText: '知道了',
    },
    Popconfirm: {
        okText: '確定',
        cancelText: '取消',
    },
    Transfer: {
        searchPlaceholder: '搜尋資料',
        itemUnit: '項目',
        itemsUnit: '項目',
    },
    Upload: {
        uploading: '正在上傳...',
        removeFile: '刪除檔案',
        uploadError: '上傳失敗',
        previewFile: '檔案預覽',
        downloadFile: '下载文件',
    },
    Empty: {
        description: '無此資料',
    },
    PageHeader: {
        back: '返回',
    },
};
