export default {
  today: 'ئەمڕۆ',
  now: 'ئێستا',
  backToToday: 'گەڕانەوە بۆ ڕۆژی',
  ok: 'باشە',
  clear: 'پاک کردن',
  month: 'مانگ',
  year: 'ساڵ',
  timeSelect: 'هەڵبژاردنی کات',
  dateSelect: 'هەڵبژاردنی ڕێکەوت',
  monthSelect: 'مانگێک هەلبژێرە',
  yearSelect: 'ساڵێک هەڵبژێرە',
  decadeSelect: 'سەدەیەک هەڵبژێرە',
  yearFormat: 'YYYY',
  dateFormat: 'M/D/YYYY',
  dayFormat: 'D',
  dateTimeFormat: 'M/D/YYYY HH:mm:ss',
  monthBeforeYear: true,
  previousMonth: 'مانگی پێشو (PageUp)',
  nextMonth: 'مانگی داهاتو (PageDown)',
  previousYear: 'پارساڵ (Control + left)',
  nextYear: 'ساڵی داهاتو (Control + right)',
  previousDecade: 'دەیە پێشو',
  nextDecade: 'دەیە داهاتو',
  previousCentury: 'سەدەی پێشو',
  nextCentury: 'سەدەی داهاتو',
};
