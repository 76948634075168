import CalendarLocale from '../../vc-calendar/src/locale/hi_IN';
import TimePickerLocale from '../../time-picker/locale/hi_IN';
// Merge into a locale object
const locale = {
    lang: Object.assign({ placeholder: 'तारीख़ चुनें', rangePlaceholder: ['प्रारंभ तिथि', 'समाप्ति तिथि'] }, CalendarLocale),
    timePickerLocale: Object.assign({}, TimePickerLocale),
};
// All settings at:
// https://github.com/vueComponent/ant-design-vue/blob/master/components/date-picker/locale/example.json
export default locale;
