import Pagination from '../vc-pagination/locale/tr_TR';
import DatePicker from '../date-picker/locale/tr_TR';
import TimePicker from '../time-picker/locale/tr_TR';
import Calendar from '../calendar/locale/tr_TR';
export default {
    locale: 'tr',
    Pagination,
    DatePicker,
    TimePicker,
    Calendar,
    global: {
        placeholder: 'Lütfen seçiniz',
    },
    Table: {
        filterTitle: 'Menü Filtrele',
        filterConfirm: 'Tamam',
        filterReset: 'Sıfırla',
        selectAll: 'Hepsini Seç',
        selectInvert: 'Tersini Seç',
        sortTitle: 'Sırala',
    },
    Modal: {
        okText: 'Tamam',
        cancelText: 'İptal',
        justOkText: 'Tamam',
    },
    Popconfirm: {
        okText: 'Tamam',
        cancelText: 'İptal',
    },
    Transfer: {
        titles: ['', ''],
        searchPlaceholder: 'Arama',
        itemUnit: 'Öğe',
        itemsUnit: 'Öğeler',
    },
    Upload: {
        uploading: 'Yükleniyor...',
        removeFile: `Dosyayı kaldır`,
        uploadError: 'Yükleme Hatası',
        previewFile: `Dosyayı Önizle`,
        downloadFile: 'Dosyayı indir',
    },
    Empty: {
        description: 'Veri Yok',
    },
    Icon: {
        icon: 'icon',
    },
    Text: {
        edit: 'düzenle',
        copy: 'kopyala',
        copied: 'kopyalandı',
        expand: 'genişlet',
    },
};
