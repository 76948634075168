import Pagination from '../vc-pagination/locale/zh_CN';
import DatePicker from '../date-picker/locale/zh_CN';
import TimePicker from '../time-picker/locale/zh_CN';
import Calendar from '../calendar/locale/zh_CN';
import ColorPicker from '../color-picker/locale/zh_CN';
export default {
    locale: 'zh-cn',
    Pagination,
    DatePicker,
    TimePicker,
    Calendar,
    ColorPicker,
    // locales for all comoponents
    global: {
        placeholder: '请选择',
    },
    Table: {
        filterTitle: '筛选',
        filterConfirm: '确定',
        filterReset: '重置',
        selectAll: '全选当页',
        selectInvert: '反选当页',
        sortTitle: '排序',
        expand: '展开行',
        collapse: '关闭行',
    },
    Modal: {
        okText: '确定',
        cancelText: '取消',
        justOkText: '知道了',
    },
    Popconfirm: {
        cancelText: '取消',
        okText: '确定',
    },
    Transfer: {
        searchPlaceholder: '请输入搜索内容',
        itemUnit: '项',
        itemsUnit: '项',
    },
    Upload: {
        uploading: '文件上传中',
        removeFile: '删除文件',
        uploadError: '上传错误',
        previewFile: '预览文件',
        downloadFile: '下载文件',
    },
    Empty: {
        description: '暂无数据',
    },
    Icon: {
        icon: '图标',
    },
    Text: {
        edit: '编辑',
        copy: '复制',
        copied: '复制成功',
        expand: '展开',
    },
    PageHeader: {
        back: '返回',
    },
};
