import Pagination from '../vc-pagination/locale/ku_KU';
import DatePicker from '../date-picker/locale/ku_KU';
import TimePicker from '../time-picker/locale/ku_KU';
import Calendar from '../calendar/locale/ku_KU';
export default {
    locale: 'ku-ku',
    Pagination,
    DatePicker,
    TimePicker,
    Calendar,
    Table: {
        filterTitle: 'مێنوی پاڵاوتن',
        filterConfirm: 'پشتڕاستکردنەوە',
        filterReset: 'پاک کردن',
        selectAll: 'هەڵبژاردنی هەموو',
        selectInvert: 'هەڵبژزاردنی پێچەوانە',
    },
    Modal: {
        okText: 'باشە',
        cancelText: 'پاشگەزبونەوە',
        justOkText: 'باشە',
    },
    Popconfirm: {
        okText: 'باشە',
        cancelText: 'پاشگەزبونەوە',
    },
    Transfer: {
        searchPlaceholder: 'گەڕان',
        itemUnit: 'یەکە',
        itemsUnit: 'یەکە',
    },
    Upload: {
        uploading: 'بارکردن...',
        removeFile: 'پاککردنی فایل',
        uploadError: 'هەڵەی بارکردن',
        previewFile: 'پیشاندانی فایل',
        downloadFile: 'وەرگرتنی فایل',
    },
    Empty: {
        description: 'هیچ زانیاریەک نیە',
    },
};
